import { BuilderComponent } from '@builder.io/react';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';

const defaultDescription = `Jeffpiscine vous accompagne dans vos démarches de construction et de rénovation piscine dans le secteur de Bar-le-Duc (55)
Nous vous proposons des solutions simples et sur mesure, en adéquation avec vos attentes et votre budget.

Parce-que chaque projet est unique, notre société 100% indépendante met à votre disposition une multitude de possibilités, afin que le résultat vous ressemble.

Avec Jeffpiscine, vous bénéficiez d'une véritable expertise, d'un suivi efficace, de conseils avisés et d'un service de proximité.`;

const defaultTitle = 'Jeffpiscine.fr';

function LandingPageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const landingPage = models.landingPage[0]?.content;

  return (
    <>
      <Helmet>
        <title>{(landingPage && landingPage.data.title) || defaultTitle}</title>

        <meta
          name="description"
          content={
            (landingPage && landingPage.data.description) || defaultDescription
          }
        />
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent
        renderLink={Link}
        name="landing-page"
        content={landingPage}
      />
    </>
  );
}

export default LandingPageTemplate;

export const landingPageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      landingPage(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
